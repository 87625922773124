<template>
  <div
    class="flex justify-between items-center bg-blue-light-1 rounded p-2 max-w-1/3"
  >
    <div>
      <p class="font-semibold text-mdh text-grey-dark-1">
        {{ title }}
      </p>
      <p class="text-sm-2 text-grey-light">
        {{ participants }}
      </p>
      <p class="text-sm-2 text-grey-light">
        {{ participantsPreview }}
      </p>
    </div>
    <Button text="Edit" size="medium" type="primary-white" />
  </div>
</template>

<script>
import Button from "../../../components/Button/Button.vue";
export default {
  name: "GroupCard",
  components: { Button },
  props: { group: { type: Object, default: null } },
  computed: {
    title() {
      return this.group ? this.group.title : "North Carolina";
    },
    participants() {
      return this.group
        ? this.group.patricipants.length
        : "3" + " Participants";
    },
    participantsPreview() {
      return this.group
        ? this.group.patricipants
        : "David Cox, Robert Perez and 1 more";
    },
  },
};
</script>
