<template>
  <div>
    <div class="flex">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
       {{ title }} {{ displayVersionForSeller(productVersion) }} /
      </p>
      <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
        Custom Slicers (Optional)
      </p>
      <div class="flex flex-grow justify-end">
        <Button
          text="Back"
          type="secondary-left"
          size="medium"
          :onClick="goBack"
          class="mr-2"
        />
        <Button
          text="Next"
          type="primary-right"
          size="medium"
          :onClick="goNext"
        />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-3">
        Create Custom Data Slicers
      </p>
      <div class="px-2">
        <p class="text-mdh text-grey-dark-1 mb-4">
          Aside from assessing participants within a certain job role, you can
          also create custom data slicers (e.g. departments, locations, etc.) to
          filter assessment results and tailor reporting to your needs.
        </p>
        <Button
          text="Create Slicer"
          type="primary-plus-left"
          size="medium"
          class="mb-2"
          :onClick="open"
        />
        <div v-if="hasGroups">
          <p class="text-mdh text-grey-dark-1 mb-2">Showing 1 Slicer</p>
          <GroupCard />
        </div>
        <div v-else>
          <p class="text-mdh text-grey-dark-1">No groups created yet.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store";
import GroupCard from "../components/GroupCard.vue";
import Button from "../../../components/Button/Button.vue";
export default {
  components: { Button, GroupCard },
  name: "Groups",
  data: () => ({ hasGroups: true }),
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
    }),
    productVersion(){
      return this.generalInfo.productVersion;
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
    }),
    open() {
      this.openModal({ modal: "createGroup" });
    },
    goNext() {
      this.$router.push("/assessments/settings");
    },
    goBack() {
      this.$router.push("/assessments/targets");
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    }
  },
  beforeRouteEnter(to, _, next) {
    const title = store.getters["assessmentSetup/getAssessmentTitle"];
    to.meta.sidenav.title = title + " Setup";
    next();
  },
};
</script>
